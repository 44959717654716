<div class="submit-lead">
	<div class="header">
		<img src="/luhambo-leads-management/assets/assupol.png" />
	</div>

	<div class="body" [ngClass]="{'flip': success, 'back': success}">
		<div *ngIf="!success">
			<pw-input [data]="{name: 'name', label: 'First Name:', type: 'text'}" [parentFormGroup]="assupolFuneralForm" [formStatus]="formStatus"></pw-input>

			<pw-input [data]="{name: 'surname', label: 'Surname:', type: 'text'}" [parentFormGroup]="assupolFuneralForm" [formStatus]="formStatus"></pw-input>

			<pw-input [data]="{name: 'telephone', label: 'Cellphone:', type: 'number'}" [parentFormGroup]="assupolFuneralForm" [formStatus]="formStatus"></pw-input>
			
			<pw-dropdown [data]="agentData" [parentFormGroup]="assupolFuneralForm"></pw-dropdown>

			<pw-button [data]="{name: 'submit', label: 'Submit', disabled: false}" (click)="submitLead()"></pw-button>
		</div>

		<div class="back message" *ngIf="success">
			<div class="message__heading">
				Lead Submitted
			</div>

			<div class="message__message">
				Your details have been successfully submitted. An assistant will contact you shortly. To help speed up
				the process, have your ID ready.
			</div>
		</div>
	</div>

	<div class="reset" *ngIf="success" >
		<pw-button [data]="{name: 'ok', label: 'Ok', disabled: false}" (click)="resetForm()"></pw-button>
	</div>

	<div class="footer">
		Assupol Life Limited Reg No. 2010/025083/06 | An authorized financial services provider | Sitemap | © Copyright 2020 Assupol
	</div>
</div>
