<div class="submit-lead">
  <div class="header">
    <img src="../../assets/king-price.png"/>
  </div>

  <div class="body" [ngClass]="{'flip': success, 'back': success}">
    <div *ngIf="!success">
      
      <pw-input [data]="{name: 'firstname', label: 'First name:', type: 'text'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>
      
      <!-- <pw-input [data]="{name: 'lastName', label: 'Last name:', type: 'text'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input> -->
      
      <pw-input [data]="{name: 'contactNumber', label: 'Contact number:', type: 'number'}" [parentFormGroup]="leadForm" [formStatus]="formStatus" (keyup)="completeLead($event)"></pw-input>

      <pw-dropdown [data]="agentData" [parentFormGroup]="leadForm"></pw-dropdown>

      <pw-button [data]="{name: 'submit', label: 'Submit', disabled: false}" (click)="submitLead()"></pw-button>
    </div>
  </div>

  <div class="message-container" [ngClass]="{'message-container--is-visible': success}">
    <div class="message-wrapper" *ngIf="success">
      <div class="message__heading">
        Lead Submitted
      </div>

      <div class="message__message">
        Your details have been successfully submitted. An assistant will contact you shortly. To help speed up the process, have your ID ready.
      </div>

      <div class="button-wrapper pw-button--secondary">
          <pw-button [data]="{name: 'reset', label: 'Done', disabled: false}" (click)="resetForm()"></pw-button>
      </div>
    </div>
  </div>

  <div class="footer">
    &copy; Copyright 2022 Kaleva Media (PTY) Ltd.
  </div>
</div>
