import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../environments/environment.prod';
import * as $ from 'jquery';
import { GoogleApiIntegrationService } from '../google-api-integration.service'
import { GoogleSheet, ServiceAccount } from 'google-sheets-manager';
import * as data from '../../../luhambo-leads-347a1989fe46.json';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'flow-submit-lead',
  templateUrl: './flow-submit-lead.component.html',
  styleUrls: ['./flow-submit-lead.component.css']
})
export class FlowSubmitLeadComponent implements OnInit {

  dropdownData: any;
  agentData: any;
  isSuccess: boolean = false;
  isError: boolean = false;
  message: string = '';
  leadForm: FormGroup;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  }
  validationMessages: any;
  errorCount: number;
  error: boolean;
  showMessage: boolean;
  API_URL = environment.apiUrl;
  success: boolean = false;
  fallbackNames: any[] = [
    "Mandisi",
    "Romeo",
    "Fortunate Matlala",
    "cyril",
    "Kgomotso",
    "Noluthando",
    "offense",
    "Lesego",
    "Maanda",
    "Eric",
    "George Makgato",
    "Khanyiswa Phakalane",
    "Mishack",
    "Steve",
    "Ntombi",
    "Constance",
    "Calvin"
  ];  

  constructor(private formBuilder: FormBuilder, private googleApiIntegrationService: GoogleApiIntegrationService) {
    this.dropdownData = {
      name: 'vdn',
      label: 'Product:',
      options: [{name: 'life', label: 'Life', code: '1LFTS'}, {name: 'funeral', label: 'Funeral', code: '1FFTS'}],
      placeholder: 'Select Product'
    };
    
    this.agentData = {
      name: 'agentName',
      label: 'Agent Name:',
      options: [],
      placeholder: 'Select Name'
    };
  }

  ngOnInit() {
    this.createForm();
    // this.fallbackNames.map((name, index) => {
    //   console.log(name)
    //     this.agentData.options[index] = {name: 'agent-' + index, label: name};
    // });

    this.googleApiIntegrationService.readNames();
    this.googleApiIntegrationService.names.subscribe((names) => {
      // console.log(names);
      let namesArray = JSON.parse(names);
      namesArray.map((nameArray, index) => {
        this.agentData.options[index] = {name: 'agent-' + index, label: nameArray[0]};
      });
    });

    console.log(this.agentData);
  }

  submitLead() {
    if (this.leadForm.status !== 'VALID') {
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }

    let name = this.leadForm.controls['name'].value;
    let surname = this.leadForm.controls['surname'].value;
    let telephone = this.leadForm.controls['telephone'].value;
    let vdn = this.leadForm.controls['vdn'].value;
    let agentName = this.leadForm.controls['agentName'].value;

    $.post(this.API_URL, {
      name: name,
      tel: telephone,
      vdn: vdn.code,
    }, (response, status) => {
      this.error = true;
      this.showMessage = true;
      console.log(response);
      if (status === "success") {
        this.message = "Fresh Lead Submitted!"
        this.isSuccess = true;
        this.isError = false;
        this.success = true;

        this.googleApiIntegrationService.writeToSheet({
          firstname: name,
          surname: surname,
          phone: telephone,
          product: vdn.label,
          agentName: agentName.label,
          status: 'Submitted'
        });
      } else {
        if (status === "error") {
          this.message = "Oops, an error has occured. Please try again."
          this.isError = true;
          this.isSuccess = false;
          console.log(status);
          console.log(name)
          console.log(telephone)

          this.googleApiIntegrationService.writeToSheet({
            firstname: name,
            surname: surname,
            phone: telephone,
            product: vdn.label,
            agentName: agentName.label,
            status: 'not submitted'
          });
        }
      }
    }, "html");
  }

  createForm() {
    this.leadForm = this.formBuilder.group({
      name: ['', Validators.required],
      telephone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      vdn: '',
      agentName: '',
      surname: ['', Validators.required],
    });

    this.validationMessages = {
      name: {
        'required': 'Please enter a valid name'
      },
      surname: {
        'required': 'Please enter a valid surnname'
      },
      telephone: {
        'required': 'Please enter a valid number',
        'maxlength': 'Please enter a number not greater than 10 charaters',
        'minlength': 'Please enter a number not less than 10 charaters'
      }
    }

    this.formStatus['formErrors'] = {
      name: '',
      surname: '',
      telephone: ''
    }

    this.leadForm.valueChanges.subscribe((data) => {
      this.formIsValid();
    })
  }

  formIsValid() {
    if (!this.leadForm) {
      return;
    }

    const form = this.leadForm;
    this.errorCount = 0;

    // tslint:disable-next-line:forin
    for (const field in this.formStatus['formErrors']) {
      // clear previous error message (if any)
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        // tslint:disable-next-line:forin

        for (const key in control.errors) {

          this.formStatus['formErrors'][field] += messages[key] + ' ';

          this.errorCount++;
        }

      }
    }
  }

}
