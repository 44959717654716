import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FlowSubmitKingpriceLeadComponent } from '../flow-submit-kingprice-lead/flow-submit-kingprice-lead.component';
import { FlowLandingPageComponent } from '../flow-landing-page/flow-landing-page.component';
import { AtaOneLifeComponent } from '../ata-one-life/ata-one-life.component';
import { AutoAndGeneralComponent } from '../auto-and-general/auto-and-general.component';
import { BudgetInsuranceComponent } from '../budget-insurance/budget-insurance.component';
import { FirstForWomanComponent } from '../first-for-woman/first-for-woman.component';
import { DialDirectComponent } from '../dial-direct/dial-direct.component';
import { CampaignOverviewComponent } from '../reporting/campaign-overview/campaign-overview.component';
import { PwCampaignTemplateComponent } from '@kaleva-media/purple-water-next';
import { environment } from 'src/environments/environment';


const routes: Routes = [
  { path: '', component: FlowLandingPageComponent},
	// { path: 'home', component: FlowLandingPageComponent},
  // { path: 'kingprice', component: FlowSubmitKingpriceLeadComponent },
  // { path: '1life', component: AtaOneLifeComponent },
  // { path: 'auto-and-general', component: AutoAndGeneralComponent },
  // { path: 'budget-insurance', component: BudgetInsuranceComponent },
  // { path: 'first-for-women', component: FirstForWomanComponent },
  // { path: 'dial-direct', component: DialDirectComponent },
  // { path: 'campaign-overview', component: CampaignOverviewComponent }
];

environment.campaigns.map((campaign) => {
  routes.push ({path: campaign.path, component: PwCampaignTemplateComponent, data: campaign});
})

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
