// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  crmApiUrl: 'https://luhambomarketing.co.za/Luhambo-CRM-dev/apis/db-api.php',
  kingPriceWriterApi: 'https://atarocol.co.za/leads-application-dev/writers/kingPriceSheetWriter.php',
  kingPriceSubmitterApi: 'https://atarocol.co.za/leads-application-dev/submitters/submit-kingprice-lead.php',
  readNamesApi: 'https://atarocol.co.za/leads-application-dev/readers/at-readnames.php',
  lifeWriterApi: 'https://atarocol.co.za/leads-application-dev/writers/atLifeWriter.php',
  autoAndGeneralWriterApi: 'https://atarocol.co.za/leads-application-dev/writers/autoAndGeneralWriter.php',
  budgetInsuranceWriterApi: 'https://atarocol.co.za/leads-application-dev/writers/budgetInsuranceWriter.php',
  firstForWomenWriterApi: 'https://atarocol.co.za/leads-application-dev/writers/firstForWomenWriter.php',
  dialDirectWriterApi: 'https://atarocol.co.za/leads-application-dev/writers/dialDirectWriter.php',
  firebaseConfig: {
    apiKey: "AIzaSyB86WbfRkO-rdcJAPuWBNqQbSftEKBy9E0",
    authDomain: "atarocol.firebaseapp.com",
    projectId: "atarocol",
    storageBucket: "atarocol.appspot.com",
    messagingSenderId: "692895634417",
    appId: "1:692895634417:web:4ce9371719b14b0e0e6a50"
  },
  campaigns: [
    {
      name: 'prestigeMedical',
      title: 'Prestige Medical',
      type: 'custom', //affiliate || telesure || custom
      path: 'prestige-medical',
      imgSrc: 'prestige.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '#', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'strawberryWorx',
      title: 'Strawberry Worx',
      type: 'custom', //affiliate || telesure || custom
      path: 'strawberry-worx',
      imgSrc: 'strawberrybillboards.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'solidStreams',
      title: 'Solid Streams',
      type: 'custom', //affiliate || telesure || custom
      path: 'solid-streams',
      imgSrc: 'Solid_logo.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'cartrack',
      title: 'Cartrack',
      type: 'custom', //affiliate || telesure || custom
      path: 'cartrack',
      imgSrc: 'cartrack.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'kingPrice',
      title: 'King Price',
      type: 'custom', //affiliate || telesure || custom
      path: 'kingprice',
      imgSrc: 'king-price.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'mixTelematics',
      title: 'Mixed Telematics',
      type: 'custom', //affiliate || telesure || custom
      path: 'mix-telematics',
      imgSrc: 'mix_telematics.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'wonga',
      title: 'Woonga',
      type: 'custom', //affiliate || telesure || custom
      path: 'wonga',
      imgSrc: 'wonga.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'assupol',
      title: 'Assupol',
      type: 'custom', //affiliate || telesure || custom
      path: 'assupol',
      imgSrc: 'assupol.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'autoAndGeneral',
      title: 'Auto and General',
      type: 'custom', //affiliate || telesure || custom
      path: 'auto-and-general',
      imgSrc: 'auto-and-general.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'budget',
      title: 'Budget Insurance',
      type: 'custom', //affiliate || telesure || custom
      path: 'budget-insurance',
      imgSrc: 'budget-insurance-logo.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'taxiSure',
      title: 'Taxi Sure',
      type: 'custom', //affiliate || telesure || custom
      path: 'taxi-sure',
      imgSrc: 'rightsure.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'dotsure',
      title: 'Dotsure',
      type: 'custom', //affiliate || telesure || custom
      path: 'dotsure',
      imgSrc: 'dotsure.svg',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'dialDirect',
      title: 'Dial Direct',
      type: 'custom', //affiliate || telesure || custom
      path: 'dial-direct',
      imgSrc: 'dial-direct.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'firstForWomen',
      title: 'First for Women',
      type: 'custom', //affiliate || telesure || custom
      path: 'firstForWomen',
      imgSrc: 'first-for-women.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'potholeProtector',
      title: 'Pothole Protector',
      type: 'custom', //affiliate || telesure || custom
      path: 'pothole-protector',
      imgSrc: 'dotmotor.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'clienteleLife',
      title: 'Clientele Life',
      type: 'custom', //affiliate || telesure || custom
      path: 'clientele',
      imgSrc: 'clientele.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'vodalend',
      title: 'Voda Lend',
      type: 'custom', //affiliate || telesure || custom
      path: 'vodalend',
      imgSrc: 'vodalend.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'debtMate',
      title: 'Debt Mate',
      type: 'custom', //affiliate || telesure || custom
      path: 'debt-mate',
      imgSrc: 'debtmate.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'debtRescue',
      title: 'Debt Rescue',
      type: 'custom', //affiliate || telesure || custom
      path: 'debt-rescue',
      imgSrc: 'debtrescue.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'Firstname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
  ]
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
