import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../environments/environment.prod';
import * as $ from 'jquery';
import { GoogleApiIntegrationService } from '../google-api-integration.service';

@Component({
  selector: 'car-loan',
  templateUrl: './car-loan.component.html',
  styleUrls: ['./car-loan.component.css']
})
export class CarLoanComponent implements OnInit {
  // API_URL = environment.apiUrl_budget;
  API_URL = "http://insurecover.co.za/luhambo-leads-management/car-loan-submitter.php";
  OFFER_ID: string = "377";
  AFF_ID: string = "18897";
  AFFILIATE_SHORT_CODE: string = "JMAFFSite10761";
  LEAD_SOURCE: string = "JMSMS";
  CHANNEL: string = "SMS";
  PRODUCT: string = "CAR-INSURANCE";

  success: boolean = false
  budgetInsuranceForm: FormGroup;
  errorCount: number;
  agentData: any;
  productData: any;
  validationMessages: any;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  };


  constructor(private formBuilder: FormBuilder, private googleApiIntegrationService: GoogleApiIntegrationService) {
    this.productData = {
      name: 'product',
      label: 'Product:',
      options: [
        {name: 'vehicleInsurance', label: 'Vehicle Insurance'}, 
        {name: 'homeInsurance', label: 'Home Insurance'}],
      placeholder: 'Select Product'
    };
    
    this.agentData = {
      name: 'agentName',
      label: 'Agent Name:',
      options: [],
      placeholder: 'Select Name'
    };
  }

  ngOnInit() {
    this.createForm();
    this.budgetInsuranceForm.get('product').disable();
    this.budgetInsuranceForm.get('tnc').disable();

    this.googleApiIntegrationService.readFVNames();
    this.googleApiIntegrationService.names.subscribe((names) => {
      let namesArray = JSON.parse(names);
      namesArray.map((nameArray, index) => {
        this.agentData.options[index] = {name: 'agent-' + index, label: nameArray[0]};
      });
    });
  }

  createForm() {
    this.budgetInsuranceForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      telephone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      tnc: [true, Validators.required],
      agentName: '',
      product: ['CAR-INSURANCE']
    });

    this.validationMessages = {
      name: {
        'required': 'Please enter a valid name'
      },
      surname: {
        'required': 'Please enter a valid surnname'
      },
      telephone: {
        'required': 'Please enter a valid number',
        'maxlength': 'Please enter a number not greater than 10 charaters',
        'minlength': 'Please enter a number not less than 10 charaters'
      },
      tnc: {
        'required': 'Please check if the customer has accepted the terms & conditions'
      }
    }

    this.formStatus['formErrors'] = {
      name: '',
      surname: '',
      telephone: '',
      tnc: '',
      product: ''
    }

    this.budgetInsuranceForm.valueChanges.subscribe((data) => {
      this.formIsValid();
    })
  }

  formIsValid() {
    if (!this.budgetInsuranceForm) {
      return;
    }

    const form = this.budgetInsuranceForm;
    this.errorCount = 0;

    // tslint:disable-next-line:forin
    for (const field in this.formStatus['formErrors']) {
      // clear previous error message (if any)
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        
        for (const key in control.errors) {
          this.formStatus['formErrors'][field] += messages[key] + ' ';
          this.errorCount++;
        }

      }
    }
  }

  submitLead() {
    if (this.budgetInsuranceForm.status !== 'VALID') {
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }

    let name = this.budgetInsuranceForm.controls['name'].value;
    let surname = this.budgetInsuranceForm.controls['surname'].value;
    let telephone = this.budgetInsuranceForm.controls['telephone'].value;
    let agentName = this.budgetInsuranceForm.controls['agentName'].value;
    let tnc = this.budgetInsuranceForm.get('tnc').value;
    let product = this.budgetInsuranceForm.get('product').value;
    let language = 'English';

    this.success = true;
    $.post(this.API_URL, {
      AffiliateShortCode: this.AFFILIATE_SHORT_CODE,
      FirstName: name,
      LastName: surname,
      CellNumber: telephone,
      AcceptTerms: tnc,
      LeadSource: this.LEAD_SOURCE,
      Aff_Id: this.AFF_ID,
      Channel: this.CHANNEL,
      Product: this.PRODUCT,
      Language: language,
      Offer_Id: this.OFFER_ID
    }, (response, status) => {
      let resp = JSON.parse(response);
      console.log(resp);

      if (status === "success") {
        this.success = true;
        this.googleApiIntegrationService.writeToFVSheet({
          firstName: name,
          surname: surname,
          phone: telephone,
          product: this.PRODUCT,
          agentName: agentName.label,
          status: resp.response,
          reason: resp.leadId
        });
      } else {
        if (status === "error") {

          this.googleApiIntegrationService.writeToFVSheet({
            firstName: name,
            surname: surname,
            phone: telephone,
            product: this.PRODUCT,
            agentName: agentName.label,
            status: resp.response,
            reason: resp.leadId
          });
        }
      }
    }, "json");
  }

  resetForm() {
    this.budgetInsuranceForm.reset();
    this.success = false;
  }

}
