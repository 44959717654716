import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../environments/environment.prod';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
import { GoogleApiIntegrationService } from '../google-api-integration.service';
import { PwFirebaseService, PwThemeingService, PwThemeModel } from '@kaleva-media/purple-water-next';

@Component({
  selector: 'flow-submit-kingprice-lead',
  templateUrl: './flow-submit-kingprice-lead.component.html',
  styleUrls: ['./flow-submit-kingprice-lead.component.scss']
})
export class FlowSubmitKingpriceLeadComponent implements OnInit {

  dropdownData: any;
  isSuccess: boolean = false;
  isError: boolean = false;
  message: string = '';
  leadForm: FormGroup;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  }
  validationMessages: any;
  errorCount: number;
  error: boolean;
  showMessage: boolean;
  API_URL = environment.kingPriceSubmitterApi;
  API_CALLER_URL = environment.localeApi;
  success: boolean = false;
  KPCID: number = 811471;
  agentData: any;
  _theme: PwThemeModel;

  constructor(
    private formBuilder: FormBuilder,
    private googleApiIntegrationService: GoogleApiIntegrationService,
    private theme: PwThemeingService,
    private firebase: PwFirebaseService) {
    this.agentData = {
      name: 'agentName',
      label: 'Agent Name:',
      options: [],
      placeholder: 'Select Name'
    };
  }

  ngOnInit() {
    this.createForm();
    this.googleApiIntegrationService.readAtarocolAgentNames();
    this.googleApiIntegrationService.names.subscribe((names) => {
      let namesArray = JSON.parse(names);
      namesArray.map((nameArray, index) => {
        this.agentData.options[index] = {name: 'agent-' + index, label: nameArray[0]};
      });
    });

    this._theme = new PwThemeModel({
      borderColor: '#B01D1F',
      textColor: '#B01D1F',
      buttonBgColor: '#B01D1F',
      buttonTextColor: '#fff',
      errorColor: 'red',
      labelColor: 'black',
      dropdownOptionBgColor: '#B01D1F',
      dropdownOptionTextColor: '#fff',
      buttonBorderColor: '#B01D1F'
    })
    this.theme.setTheme(this._theme);
  }

  /**
   * This function will generate a unique string.
   * Params: none
   */
  generateSecurityPhrase() {
    let text = "fmg_";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 12; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  /**
   * This function will submit the form.
   * Params: none
   */
  submitLead(): Promise<any> {
    if (this.leadForm.status !== 'VALID') {
      this.markAllAsDirty();
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }
    
    let payLoad = {
      first_name: this.leadForm.controls['firstname'].value,
      last_name: this.leadForm.controls['lastName'].value,
      phone_number: this.leadForm.controls['contactNumber'].value,
      agent_name: this.leadForm.controls['agentName'].value,
      campaignName: 'kingprice'
    };

    this.firebase.saveRecord({
      id: payLoad.phone_number,
      name: payLoad.first_name,
      surname: payLoad.last_name,
      phone: payLoad.phone_number,
      campaignName: payLoad.campaignName,
      agentName: payLoad.agent_name.label,
      date: this.getOptinDate(),
      day: this.getOptinDateObj().day,
      month: this.getOptinDateObj().month,
      year: this.getOptinDateObj().year
    }, 'leads').then(() => {
      this.firebase.updateCount('leads', payLoad.campaignName).then(() => {
        this.firebase.updateCount('leads');
      });
    });

    // $.post('http://insurecover.co.za/luhambo-leads-sit/submit-kingprice-lead.php', {
    $.post(this.API_URL, {
      contactNumber: payLoad.phone_number,
      firstname: payLoad.first_name,
      KPCID: this.KPCID
    }, (response, status) => {
      this.error = true;
      this.showMessage = true;
      
      if (status === "success") {
        this.message = "Lead Submitted!"
        this.isSuccess = true;
        this.isError = false;
        this.success = true;

        this.googleApiIntegrationService.writeAtKingPrice({
          firstName: payLoad.first_name,
          phone: payLoad.phone_number,
          agentName: payLoad.agent_name.label,
          status: "Creation Successful"
        });
      } else {
        if (status === "error") {
          this.message = "Oops, an error has occured. Please try again."
          this.isError = true;
          this.isSuccess = false;
        }
      }
    });
    
    this.message = "Lead Submitted!"
    this.isSuccess = true;
    this.isError = false;
    this.success = true;

    setTimeout(() => {
      this.isSuccess = !this.isSuccess;
      this.isError = !this.isError;
      this.success = !this.success;
    }, 5000);
  }

  /**
   * This function will reset the form.
   * Params: none
   */
  resetForm() {
    this.message = '';
    this.isSuccess = false;
    this.isError = false;
    this.success = false;
    this.leadForm.reset();
  }

  /**
   * This function will create the formGroup with all controls and Validators
   * Params: none
   */
  createForm() {
    this.leadForm = this.formBuilder.group({
      // address: [''],
      firstname: ['', [Validators.required]],
      contactNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      // idNumber: ['', [Validators.minLength(13), Validators.maxLength(13)]],
      lastName: [''],
      agentName: ''
    });

    this.validationMessages = {
      contactNumber: {
        'required': 'Please enter a valid contact number',
        'maxlength': 'Please enter a number not greater than 10 charaters',
        'minlength': 'Please enter a number not less than 10 charaters'
      },
      address: {
        'required': 'Please enter an address'
      },
      firstname: {
        'required': 'Please enter a valid first name'
      },
      lastName: {
        'required': 'Please enter a valid last name'
      },
      idNumber: {
        'required': 'Please select enter a ID number',
        'maxlength': 'Please enter an ID number not greater than 13 charaters',
        'minlength': 'Please enter an ID number not less than 13 charaters'
      }
    }

    this.formStatus['formErrors'] = {
      contactNumber: '',
      address: '',
      firstname: '',
      lastName: '',
      idNumber: '',
    }

    this.leadForm.valueChanges.subscribe((data) => {
      this.formIsValid();
    })
  }

  /**
   * This function will mark all controls as dirty
   * Params: none
   */
  markAllAsDirty() {
    Object.keys(this.leadForm.controls).map((x) => {
      this.leadForm.controls[x].markAsDirty();
    })
  }

  /**
   * This function will check if any controls have errors
   * and populate them if available.
   * Params: none
   */
  formIsValid() {
    if (!this.leadForm) {
      return;
    }

    const form = this.leadForm;
    this.errorCount = 0;

    for (const field in this.formStatus['formErrors']) {
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {

          this.formStatus['formErrors'][field] += messages[key] + ' ';

          this.errorCount++;
        }

      }
    }
  }

  /**
   * This function will submit the form when user clicks enter
   * Params: none
   */
  completeLead(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
      if (this.leadForm.status === 'VALID') {
        this.submitLead();
      } else {
        this.markAllAsDirty();
        this.formStatus.submitClicked = true;
        this.formIsValid()
      }
    }
  }

  getOptinDate() : string {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let strDate = dd + '/' + mm + '/' + yyyy;
    return strDate;
  }

  getOptinDateObj() : any {
    var today = new Date();
    let dateObj = {};
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let strDate = dd + '/' + mm + '/' + yyyy;
    dateObj['day'] = dd;
    dateObj['month'] = mm;
    dateObj['year'] = yyyy;
    dateObj['date'] = strDate;
    return dateObj;
  }
}
