import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignServiceService {

  campaigns: any[] = environment.campaigns;

  constructor() { }

  getCampaignOptions(): any[] {
    let campaignOptions: any [] = [];

    this.campaigns.map((campaign) => {
      let campaignOption = {
        label: campaign.title,
        url: campaign.path,
        imgSrc: campaign.imgSrc,
        isChild: true
      }
      campaignOptions.push(campaignOption);
    });

    return campaignOptions;
  }
}
