import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { GoogleApiIntegrationService } from '../google-api-integration.service';
import moment from 'moment';

@Component({
  selector: 'flow-submit-assupol-lead',
  templateUrl: './flow-submit-assupol-lead.component.html',
  styleUrls: ['./flow-submit-assupol-lead.component.css']
})
export class FlowSubmitAssupolLeadComponent implements OnInit {
  API_URL = "http://insurecover.co.za/luhambo-leads-management/assupol-lead-submitter.php";
  CAMP_ID: string = "FUNERAL-COVER";
  S_ID: string = "14219";
  LEAD_SOURCE: string = "JMSMS";
  AFFILIATE_SHORT_CODE: string = "JMAFFSite7778";
  CHANNEL: string = "JMAff";
  PRODUCT: string = "JMFuneral";
  OFFER_ID: string = "2233";


  success: boolean = false;
  assupolFuneralForm: FormGroup;
  errorCount: number;
  agentData: any;
  productData: any;
  validationMessages: any;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  };

  constructor(private formBuilder: FormBuilder, private googleApiIntegrationService: GoogleApiIntegrationService) {
    this.agentData = {
      name: 'agentName',
      label: 'Agent Name:',
      options: [],
      placeholder: 'Select Name'
    };
  }

  ngOnInit() {
    this.createForm();
    this.assupolFuneralForm.get('product').disable();
    this.assupolFuneralForm.get('tnc').disable();

    this.googleApiIntegrationService.readAssupolNames();
    this.googleApiIntegrationService.names.subscribe((names) => {
      let namesArray = JSON.parse(names);
      namesArray.map((nameArray, index) => {
        this.agentData.options[index] = {name: 'agent-' + index, label: nameArray[0]};
      });
    });
  }

  createForm() {
    this.assupolFuneralForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      telephone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      tnc: [true, Validators.required],
      agentName: '',
      product: ['FUNERAL-COVER']
    });

    this.validationMessages = {
      name: {
        'required': 'Please enter a valid name'
      },
      surname: {
        'required': 'Please enter a valid surnname'
      },
      telephone: {
        'required': 'Please enter a valid number',
        'maxlength': 'Please enter a number not greater than 10 charaters',
        'minlength': 'Please enter a number not less than 10 charaters'
      },
      tnc: {
        'required': 'Please check if the customer has accepted the terms & conditions'
      },
      dob: {
        'required': 'Please select a valid date'
      },
      id: {
        'required': 'Please select enter a ID number',
        'maxlength': 'Please enter an ID number not greater than 13 charaters',
        'minlength': 'Please enter an ID number not less than 13 charaters'
      },
      email: {
        'required': 'Please enter a valid email address',
        'email': 'Please enter a valid email address'
      },
    }

    this.formStatus['formErrors'] = {
      name: '',
      surname: '',
      telephone: '',
      tnc: '',
      product: '',
      dob: '',
      id: '',
      email: ''
    }

    this.assupolFuneralForm.valueChanges.subscribe((data) => {
      this.formIsValid();
    })
  }

  formIsValid() {
    if (!this.assupolFuneralForm) {
      return;
    }

    const form = this.assupolFuneralForm;
    this.errorCount = 0;

    // tslint:disable-next-line:forin
    for (const field in this.formStatus['formErrors']) {
      // clear previous error message (if any)
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formStatus['formErrors'][field] += messages[key] + ' ';
          this.errorCount++;
        }

      }
    }
  }

  getOptinDate() {
    let currentDate = new Date();
    let optinDate = moment(currentDate).format('DD/MM/YYYY hh:mm:ss')
    
    return optinDate;
  }
  formatDob(dob) {
    let dateObj = new Date(dob);
    let newDob = moment(dateObj).format('DD/MM/YYYY');

    return newDob;
  }

  submitLead() {
    if (this.assupolFuneralForm.status !== 'VALID') {
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }

    let name = this.assupolFuneralForm.controls['name'].value;
    let surname = this.assupolFuneralForm.controls['surname'].value;
    let telephone = this.assupolFuneralForm.controls['telephone'].value;
    let agentName = this.assupolFuneralForm.controls['agentName'].value;
    let tnc = this.assupolFuneralForm.get('tnc').value;
    let product = this.assupolFuneralForm.get('product').value;
    let optinDate = this.getOptinDate();

    this.success = true;
    $.post(this.API_URL, {
      FirstName: name,
      LastName: surname,
      CellNumber: telephone,
      AcceptTerms: tnc,
      SID: this.S_ID,
      Offer_Id: this.OFFER_ID,
      optInDate: optinDate,
      AffiliateShortCode: this.AFFILIATE_SHORT_CODE,
      Product: product,
      LeadSource: this.LEAD_SOURCE,
      Channel: this.CHANNEL
    }, (response, status) => {
      let resp = JSON.parse(response);
      console.log(resp);
      console.log(status);

      if (status === "success") {
        console.log('success', status)
        this.success = true;
        this.googleApiIntegrationService.writeToAssupolSheetFuneral({
          firstName: name,
          surname: surname,
          phone: telephone,
          product: product,
          agentName: agentName.label,
          status: resp.response,
          reason: resp.leadId + "",
          // dob: dob,
          optinDate: this.getOptinDate(),
          // id: id,
        });
      } else {
        let resp = JSON.parse(response);
        console.log(resp);
        console.log('error');
        if (status === "error") {
          if (!resp.leadId) {
            resp.leadId = "error";
          }
          this.googleApiIntegrationService.writeToAssupolSheetFuneral({
            firstName: name,
            surname: surname,
            phone: telephone,
            product: product,
            agentName: agentName.label,
            status: resp.response,
            reason: resp.leadId + "",
            // dob: dob,
            optinDate: this.getOptinDate(),
            // id: id,
          });
        }
      }
    }, "json");
  }

  resetForm() {
    this.assupolFuneralForm.reset();
    this.success = false;
  }
   
  

}
