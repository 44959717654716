<div class="landing-container">
    <div class="header">
        <div class="header__heading">
            Welcome to Hotleads
            <span class="header__sub-heading">
                Select a campaign to continue.
            </span>
        </div>
    </div>

    <div class="body">
      <a class="client-tile__container" *ngFor="let campaign of campaigns" href="#/{{campaign.url}}">
        <div class="client-tile__hero-image" [ngStyle]="{'background-image': 'url(../../assets/' + campaign.imgSrc + ')'}"></div>
        <div class="client-tile__description"> {{campaign.label}} </div>
      </a>
    </div>
</div>
