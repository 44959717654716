import { Component, OnInit } from '@angular/core';
import { PwFirebaseService } from '@kaleva-media/purple-water-next';

@Component({
  selector: 'campaign-overview',
  templateUrl: './campaign-overview.component.html',
  styleUrls: ['./campaign-overview.component.scss']
})
export class CampaignOverviewComponent implements OnInit {

  single: any[];
  view: any[] = [700, 400];
  data: any;

  colorScheme = {
    domain: ['#95BEE6', '#C3141A', '#F9A800', '#A56774', '#0095cd', '#B01D1F']
  };
  cardColor: string = '#232837';
  campaigns: any[] = [
    {name: '1life', value: 0},
    {name: 'autoAndGeneral', value: 0},
    {name: 'dialDirect', value: 0},
    {name: 'firstForWomen', value: 0},
    {name: 'budget', value: 0},
    {name: 'kingprice', value: 0}
  ];
  showOverView: boolean[] = [];
  gradient: boolean = true;
  
  constructor(private firebase: PwFirebaseService) {
  }

  ngOnInit(): void {
    let dataReceived = false;
    this.campaigns.map((campaign, i) => {
      this.firebase.getCollectionSize('leads/campaigns/' + campaign.name).then((e) => {
        this.campaigns[i].value = e.data().total;
        dataReceived = false;
      }).then(() => {
        dataReceived = true;
        this.showOverView.push(dataReceived)
        dataReceived = false;
      });
    });
  }

  onSelect(event) {
    console.log(event);
  }

}
