
<div class="campaign-overview campaign-overview__container">
    <div class="campaign-overview__wrapper">
        <div class="campaign-overview__heading">
            <h2>Campaign Overview</h2>
            <span class="header__sub-heading">
                See how your campaigns are performing.
            </span>
        </div>
    </div>
    <div class="campaign-overview__body">
        <ngx-charts-advanced-pie-chart *ngIf="showOverView.length === campaigns.length"
            [view]="view"
            [scheme]="colorScheme"
            [results]="campaigns"
            [gradient]="gradient"
            (select)="onSelect($event)">
        </ngx-charts-advanced-pie-chart>
    </div>
</div>