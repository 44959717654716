import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../environments/environment.prod';
import * as $ from 'jquery';
import moment from 'moment';
import { GoogleApiIntegrationService } from '../google-api-integration.service';

@Component({
  selector: 'flow-submit-clientele-lead',
  templateUrl: './flow-submit-clientele-lead.component.html',
  styleUrls: ['./flow-submit-clientele-lead.component.scss']
})
export class FlowSubmitClienteleLeadComponent implements OnInit {

  dropdownData: any;
  isSuccess: boolean = false;
  isError: boolean = false;
  message: string = '';
  leadForm: FormGroup;
  agentData: any;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  }
  validationMessages: any;
  errorCount: number;
  error: boolean;
  showMessage: boolean;
  API_URL = environment.apiUrl;
  // API_URL_ALLLIFE = environment.apiUrlAllLife;
  API_URL_CLIENTELE = 'http://insurecover.co.za/luhambo-leads-management/clienteleSubmitter.php';
  success: boolean = false;

  // CAMPAIGN_ID: string = 'fH25Tk2Z';
  // CAMPAIGN_PASSWORD: string = '7vpWcyXT';
  // SUPPLIER_CODE: string = 'FMG';
  // CAMPAIGN_NAME: string = 'MSA_Atarocol_DigitalWiFi_Fun';

  randomChar: any = '12345'


  constructor(private formBuilder: FormBuilder, private googleApiIntegrationService: GoogleApiIntegrationService) { 

    this.dropdownData = {
      name: 'product',
      label: 'Product:',
      options: [{name: '11426', label: 'Legal', code: 'luhambolegalwarmupcps'}, {name: '11425', label: 'Funeral', code: 'luhambofuneralwarmupcps'}],
      placeholder: 'Select Product'
    };

    this.agentData = {
      name: 'agentName',
      label: 'Agent Name:',
      options: [],
      placeholder: 'Select Name'
    };
  }

  ngOnInit() {
    this.createForm();
    
    this.googleApiIntegrationService.reatAtNames();
    this.googleApiIntegrationService.names.subscribe((names) => {
      // console.log(names);
      let namesArray = JSON.parse(names);
      namesArray.map((nameArray, index) => {
        this.agentData.options[index] = {name: 'agent-' + index, label: nameArray[0]};
      });
    });
  }


  createForm() {
    this.leadForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      product:'',
      contactNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      agentName: '',
    });
    this.validationMessages = {
      contactNumber: {
        'required': 'Please enter a valid contact number',
        'maxlength': 'Please enter a number not greater than 10 charaters',
        'minlength': 'Please enter a number not less than 10 charaters'
      },
      firstname: {
        'required': 'Please select enter a first name'
      },
      lastName: {
        'required': 'Please select enter a last name'
      },
      title: {
        'required': 'Please select enter a last name'
      },
      email: {
        'required': 'Please enter a valid email',
        'email': 'Please enter a valid email'
      }
    }

    this.formStatus['formErrors'] = {
      contactNumber: '',
      firstname: '',
      lastName: '',
      email: '',
      title: '',
    }

    this.leadForm.valueChanges.subscribe((data) => {
      this.formIsValid();
    });
  }

  markAllAsDirty() {
    Object.keys(this.leadForm.controls).map((x) => {
      this.leadForm.controls[x].markAsDirty();
    })
  }

  formIsValid() {
    if (!this.leadForm) {
      return;
    }

    const form = this.leadForm;
    this.errorCount = 0;

    for (const field in this.formStatus['formErrors']) {
      // clear previous error message (if any)
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {

          this.formStatus['formErrors'][field] += messages[key] + ' ';

          this.errorCount++;
        }
      }
    }
  }

  resetForm() {
    this.leadForm.reset();
    this.success = false;
  }

  submitLead() {
    const currentDateTime = moment(Date.now()).format('YYYY/MM/MDD, h:mm:ss A');
    if (this.leadForm.status !== 'VALID') {
      this.markAllAsDirty();
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }else{
    let firstname = this.leadForm.controls['firstname'].value;
    let lastName = this.leadForm.controls['lastName'].value;
    let email = this.leadForm.controls['email'].value;
    let contactNumber = this.leadForm.controls['contactNumber'].value;
    let agentName = this.leadForm.controls['agentName'].value;
    let product =   this.leadForm.controls['product'].value.code;
    let smsLocation = this.leadForm.controls['product'].value.name;
    
    $.post(this.API_URL_CLIENTELE, {
      firstname: firstname,
      lastName: lastName,
      email: email,
      contactNumber: contactNumber,
      campaign: product,
      smsLocation: smsLocation,
      date : currentDateTime
    }, (response, status) => {
      this.error = true;
      this.showMessage = true;
      console.log(response);

      if (status === "success") {
        this.message = "Lead Submitted!"
        this.isSuccess = true;
        this.isError = false;
        this.success = true;

        this.googleApiIntegrationService.writeAtClientele({
          firstName: firstname,
          surname: lastName,
          phone: contactNumber,
          email: email,
          agentName: agentName.label,
          status: "Creation Successful",
          product: (product === "luhambolegalwarmupcps")?"Legal":"Funeral"
        });

      } else {
        if (status === "error") {
          this.message = "Oops, an error has occured. Please try again."
          this.isError = true;
          this.isSuccess = false;
          this.googleApiIntegrationService.writeAtClientele({
            firstName: firstname,
            surname: lastName,
            phone: contactNumber,
            email: email,
            agentName: agentName.label,
            status: "Error Submitting"
          });
        }
      }
    }, "html");
  }
 }
}
