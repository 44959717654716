import { Component, OnInit } from '@angular/core';
import { CampaignServiceService } from '../Services/campaign-service.service';

@Component({
  selector: 'flow-landing-page',
  templateUrl: './flow-landing-page.component.html',
  styleUrls: ['./flow-landing-page.component.css']
})
export class FlowLandingPageComponent implements OnInit {

  campaigns: any[] = [];

  constructor(private campaignService: CampaignServiceService) { }

  ngOnInit() {
    this.campaigns = this.campaignService.getCampaignOptions();
  }

}
