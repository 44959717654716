import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { GoogleApiIntegrationService } from '../google-api-integration.service';
import * as $ from 'jquery';
import { PwDropdownService, PwFirebaseService, PwThemeingService, PwThemeModel } from '@kaleva-media/purple-water-next';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'dial-direct',
  templateUrl: './dial-direct.component.html',
  styleUrls: ['./dial-direct.component.scss']
})
export class DialDirectComponent implements OnInit {
  agentData: any;
  campaignType: any;
  isSuccess: boolean = false;
  isError: boolean = false;
  message: string = '';
  leadForm: FormGroup;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  }
  validationMessages: any;
  errorCount: number;
  error: boolean;
  showMessage: boolean;
  API_URL = environment.apiUrl;
  success: boolean = false;
  commentsDefaultValue: string = 'Positive response, Dial Direct';
  _theme: PwThemeModel;

  constructor(
    private formBuilder: FormBuilder,
    private googleApiIntegrationService: GoogleApiIntegrationService,
    private firebase: PwFirebaseService,
    private theme: PwThemeingService,
    private snackBar: MatSnackBar,
    private dropdownService: PwDropdownService) {
    
    this.agentData = {
      name: 'agentName',
      label: 'Agent Name:',
      options: [],
      placeholder: 'Select Name'
    };

    this.campaignType = {
      name: 'campaignType',
      label: 'Campaign Type',
      options: [
        {
          name: 'sms',
          label: 'SMS',
          value: 'DDAT1'
        },
        // {
        //   name: 'warmup',
        //   label: 'Warmup',
        //   value: 'DARTC'
        // }
        {
          name: 'warmup',
          label: 'Warmup',
          value: 'WDATW'
        }
      ],
      placeholder: 'Select Campaign Type'
    };
  }

  ngOnInit() {
    this.createForm();

    this.googleApiIntegrationService.reatAtNames();
    this.googleApiIntegrationService.names.subscribe((names) => {
      let namesArray = JSON.parse(names);
      namesArray.map((nameArray, index) => {
        this.agentData.options[index] = {name: 'agent-' + index, label: nameArray[0]};
      });
    });

    this._theme = new PwThemeModel({
      borderColor: '#F9A800',
      textColor: '#000',
      buttonBgColor: '#122e5c',
      buttonTextColor: '#fff',
      errorColor: 'red',
      labelColor: '#000',
      dropdownOptionBgColor: '#F9A800',
      dropdownOptionTextColor: '#fff'
    })
    this.theme.setTheme(this._theme);
  }

  createForm() {
    this.leadForm = this.formBuilder.group({
      name: ['', Validators.required],
      telephone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      agentName: '',
      carModel: '',
      comments: '',
      campaignType: ['', Validators.required],
      surname: ['', Validators.required],
    });

    this.validationMessages = {
      name: {
        'required': 'Please enter a valid name'
      },
      surname: {
        'required': 'Please enter a valid surnname'
      },
      campaignType: {
        'required': 'Please select a campaign type'
      },
      telephone: {
        'required': 'Please enter a valid number',
        'maxlength': 'Please enter a number not greater than 10 charaters',
        'minlength': 'Please enter a number not less than 10 charaters'
      }
    }

    this.formStatus['formErrors'] = {
      name: '',
      surname: '',
      telephone: '',
      campaignType: ''
    }

    this.leadForm.valueChanges.subscribe((data) => {
      this.formIsValid();
    });

    this.leadForm.get('comments').setValue(this.commentsDefaultValue);
  }

  submitLead() {
    if (this.leadForm.status !== 'VALID') {
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }

    let name = this.leadForm.controls['name'].value;
    let surname = this.leadForm.controls['surname'].value;
    let telephone = this.leadForm.controls['telephone'].value;
    let vdn = this.leadForm.get('campaignType').value.value;
    let agentName = this.leadForm.controls['agentName'].value;
    let carModel = this.leadForm.controls['carModel'].value;
    let comments = this.leadForm.controls['comments'].value;
    let apiComment = 'Car Model: ' + carModel + '. Comments: ' + comments;
    let campaignName = 'dialDirect';

    this.firebase.saveRecord({
      id: telephone,
      name: name,
      surname: surname,
      phone: telephone,
      vdn: vdn,
      comment: comments,
      carModel: carModel,
      agentName: agentName.label,
      campaignName: campaignName,
      date: this.getOptinDate(),
      day: this.getOptinDateObj().day,
      month: this.getOptinDateObj().month,
      year: this.getOptinDateObj().year
    }, 'leads').then(() => {
      this.firebase.updateCount('leads', campaignName).then(() => {
        this.firebase.updateCount('leads');
      });
    });

    $.post(this.API_URL, {
      name: name,
      tel: telephone,
      vdn: vdn,
      comments: apiComment
    }, (response, status) => {
      this.error = true;
      this.showMessage = true;
      console.log(response);

      let parser = new DOMParser();
      let doc = parser.parseFromString(response, 'text/html');


      if (status === "success") {
        this.message = "Fresh Lead Submitted!"
        let apiResponseTitle = this.sanitizeString($(doc).find('title')[0].innerText);

        if (apiResponseTitle.toLowerCase() !== 'error') {
          let apiResponseSuccess = this.sanitizeString($(doc).find('font')[0].innerText);
          this.message = apiResponseSuccess;
        } else {
          let apiResponseError = this.sanitizeString($(doc).find('#lblErrorDefault')[0].innerText);
          this.message = apiResponseError;
        }

        this.googleApiIntegrationService.writeToDialDirectSheet({
          firstname: name,
          surname: surname,
          phone: telephone,
          product: vdn,
          agentName: agentName.label,
          status: this.message,
          comments: apiComment
        });

        this.snackBar.open(this.message, 'Ok!', {
          verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
          horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
        });

        this.resetForm();
      } else {
        if (status === "error") {
          this.message = "Oops, an error has occured. Please try again."
          this.isError = true;
          this.isSuccess = false;

          this.googleApiIntegrationService.writeToDialDirectSheet({
            firstname: name,
            surname: surname,
            phone: telephone,
            product: vdn,
            agentName: agentName.label,
            status: 'not submitted',
            comments: apiComment
          });
        }
      }
    }, "html");
  }

  resetForm() {
    this.dropdownService.resetDropdown('campaignType');
    this.dropdownService.resetDropdown('agentName');
    this.leadForm.reset();
  }

  formIsValid() {
    if (!this.leadForm) {
      return;
    }

    const form = this.leadForm;
    this.errorCount = 0;

    // tslint:disable-next-line:forin
    for (const field in this.formStatus['formErrors']) {
      // clear previous error message (if any)
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        // tslint:disable-next-line:forin

        for (const key in control.errors) {

          this.formStatus['formErrors'][field] += messages[key] + ' ';

          this.errorCount++;
        }

      }
    }
  }

  sanitizeString(str){
    str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"");
    return str.trim();
  }

  getOptinDate() : string {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let strDate = dd + '/' + mm + '/' + yyyy;
    return strDate;
  }

  getOptinDateObj() : any {
    var today = new Date();
    let dateObj = {};
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let strDate = dd + '/' + mm + '/' + yyyy;
    dateObj['day'] = dd;
    dateObj['month'] = mm;
    dateObj['year'] = yyyy;
    dateObj['date'] = strDate;
    return dateObj;
  }
}
